import React from "react"
import { Button, Row, Col, Form, FormGroup, Label, Input } from "reactstrap"
import Layout from "../components/Layout"
import HelmetTitle from "../components/utils"
import "../scss/modules/_m-hero-contact.scss"

const ContactPage = () => {
  const tagline = "We are all ears",
    title =
      "Whether it's a code review or a project that you want to discuss or just want to say hello, We would love to hear from you. We cordially welcome your ideas, suggestions, and reviews regarding our work.",
    buttonText = "Book a Free Introductory Session",
    buttonURL = "https://calendly.com/foretheta"
  return (
    <Layout>
      <div className="contact mb-6">
        <HelmetTitle title="Contact" />
        <div className="contact-banner pt-6 mb-4 text-white text-justify">
          <Row>
            <Col xs={{ size: 10, offset: 1 }}>
              <h1 className="mb-5 font-weight-bold">{tagline} </h1>
              <h3 className="mb-5 text-justify">{title}</h3>
            </Col>
          </Row>
          <div className="cell large-offset-2 pb-6 pb-sm-5 text-center">
            <Button
              onClick={() => {
                window.location = buttonURL
              }}
            >
              {buttonText}
            </Button>
          </div>
        </div>

        <Row className="form-row">
          <Col md={{ size: 6, offset: 1 }} xs={{ size: 10, offset: 1 }}>
            <p className="font-weight-bold">
              Prefer an offline discussion? Please submit the form below.
            </p>
            <Form
              action="https://formspree.io/help@foretheta.com"
              method="POST"
            >
              <FormGroup>
                <Label for="projectName">Project name:</Label>
                <Input type="text" name="projectName" id="projectName" />
              </FormGroup>
              <FormGroup>
                <Label for="name">
                  Your name:<span className="text-danger"> *</span>
                </Label>
                <Input type="text" name="name" id="name" required />
              </FormGroup>

              <FormGroup>
                <Label for="email">
                  Your email:<span className="text-danger"> *</span>
                </Label>
                <Input type="email" name="email" id="email" required />
              </FormGroup>
              <FormGroup>
                <Label for="description">
                  Describe your proposed project:<span className="text-danger">
                    {" "}
                    *
                  </span>
                </Label>
                <Input
                  type="textarea"
                  name="description"
                  id="description"
                  required
                />
              </FormGroup>
              <Button type="submit">Send</Button>
            </Form>
          </Col>
          <Col md={4} xs={{ offset: 1 }} className="mt-md-0 mt-sm-5 contact-ul">
            <ul>
              <li>
                <p>
                  Email us at{" "}
                  <a className="lien" href="mailto:help@foretheta.com">
                    help@foretheta.com
                  </a>
                </p>
              </li>
              <li>
                <p>
                  Follow{" "}
                  <a className="lien" href="https://twitter.com/foretheta">
                    @foretheta
                  </a>{" "}
                  on Twitter
                </p>
              </li>
              <li>
                <p>
                  Contribute to Foretheta on{" "}
                  <a className="lien" href="https://github.com/foretheta">
                    GitHub
                  </a>
                </p>
              </li>
              <li>
                <p>
                  Subscribe to our blog on{" "}
                  <a className="lien" href="https://medium.com/foretheta">
                    Medium
                  </a>
                </p>
              </li>
            </ul>
          </Col>
        </Row>
      </div>
    </Layout>
  )
}

export default ContactPage
